.home-container {
    width: 90%;
    margin: auto;
    padding-top: 20px;
}

.inline-blocker a {
    display: inline-block;
}

.small-play-icon-circle {
    height: 20px;
    width: 20px;
    background-color: #fa1332;
    line-height: 20px;
    text-align: center;
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px;
}