.calendar-base {
    width: 80px;
    height: auto;
    border-radius: 5px;
    color: white;
    border: 1px solid #007bff;
    margin-right: 5px;
    cursor: pointer;
  }
  
  .calender-year {
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 12px;
    text-align: center;
    background-color: #007bff;
    border-radius: 5px 5px 0px 0px;
  }
  
  .calender-divider {
    width: 100%;
    background-color: white;
    height: 1px;
  }
  
  .calender-date {
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    color: #000;
  }
  
  .calender-day {
    text-align: center;
    font-size: 10px;
    padding-bottom: 5px;
    border-radius: 0px 0px 5px 5px;
    color: #000;
  }






/* MINI */

  .calendar-base-mini {
    width: 30px;
    height: auto;
    border-radius: 2px;
    color: white;
    border: 1px solid #070707;
    margin-right: 2px;
    cursor: pointer;
  }
  
  .calender-year-mini {
    padding-top: 2px;
    padding-bottom: 3px;
    font-size: 4px;
    text-align: center;
    background-color: #070707;
    border-radius: 2px 2px 0px 0px;
  }
  
  .calender-divider-mini  {
    width: 100%;
    background-color: white;
    height: 1px;
  }
  
  .calender-date-mini  {
    font-size: 8px;
    font-weight: 700;
    text-align: center;
    color: #000;
  }
  
  .calender-day-mini  {
    text-align: center;
    font-size: 4px;
    padding-bottom: 2px;
    border-radius: 0px 0px 2px 2px;
    color: #000;
  }