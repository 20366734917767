.add-files-container {
    padding-top: 80px;
}

#selected-files-container {
    width: 80%;
    height: 80vh;
    display: flex;
    margin: 0 auto;
    margin-top: 20px;
}

#file-viewer {
    width: 49%;
    margin-left: 2%;
}

#selected-files {
    width: 49%;
    height: 93%;
}

#add-file-iframe {
    width: 100%;
    height: calc(100vh - 190px);
  }
