#add_email {
    display: flex;
    padding: 10px;
    border: solid rgb(238, 229, 229) 1px;
    background-color: white;
  }

  .email_field {
    margin-right: 2%;
    width: 40%;
  }

  #add_button {
    margin-left: 2%;
    width: 20%;
  }

  #client_field {
    margin-left: 2%;
    width: 36%;
  }

  .emails-table {
    background-color: #f4f5fc;
    height: calc(100vh - 200px);
  }