#isstep_progress #isstep_row {
    text-align: center;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: center;
}


#isstep_progress img {
    height: 70px;
}


.isstep_col {
    position: relative;
    width: 14%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


#isstep_progress .isi {
    background: #ccc;
    width: 32px;
    height: 32px;
    color: #fff;
    border-radius: 50%;
    padding: 5px;
    z-index: 100;
}

#isstep_progress .isi-green {
    background: rgb(73, 158, 17);
    width: 32px;
    height: 32px;
    color: #fff;
    border-radius: 50%;
    padding: 5px;
    z-index: 100;
}

.image-grayscale {
    filter: grayscale(100%);
}


#isstep_progress p::before {
    content: '';
    height: 5px;
    width: 100%;
    background-color: #ccc;
    display: block;
    position: absolute;
    left: 0;
    top: 105px;
    z-index: 1;
}

#isstep_progress .p-colored::before {
    content: '';
    height: 5px;
    width: 100%;
    background-color: rgb(73, 158, 17);
    display: block;
    position: absolute;
    left: 0;
    top: 105px;
    z-index: 1;
}

#isstep_progress .isstep_col:first-child p::before {
    width: 50%;
    left: 50%;
}

#isstep_progress .isstep_col:last-child p::before {
    width: 50%;
}

@media screen and (max-width: 800px) {
}