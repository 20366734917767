.dashboard-container {
    width: 100%;
    margin: auto;
    margin-top: 100px;
    display: flex;
}

#db-left-container {
    width: 350px;
    padding-left: 20px;
}

#db-right-container {
    margin-right: 20px;
    width: calc( 100% - 380px );
    margin-left: 10px;
    background-color: #f8fafe;
    height: calc( 100vh - 100px);
}

.db-left-selected {
    background-color: azure;
}

.db-left-normal {
    background-color: transparent;
}

.circle-icon {
    display: inline-block;
    padding: 2px;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 12px;
    background-color: coral;
    margin-left: 20px;
}

.center-vertical {
    display: flex;
    align-items: center;
    justify-content: start;
}
