  .container-sc-group {
    height: calc(100vh - 80px);
    padding-top: 80px;
  }
  
  .container-sc-section {
    border: solid black 1px;
  }

  .left-right-flex {
    display: flex;
  }

  .one-sc-group {
    width: 65%;
    float: left;
    background-color: #f4f5fc;
    height: calc(100vh - 130px);
  }
  
  .bg-red-sc-group{
    background-color: #e1f5f2;
  }

  .bg-normal-sc-group{
    background-color: transparent;
  }

  .bg-red-sc-group-selected{
    background-color: #d2eeea;
  }

  .bg-normal-sc-group-selected{
    background-color: #e4e6f5;
  }

  .bg-selected-sc-group {
    background-color: #edeffa;
  }

  .relative-position {
    position: relative;
  }

  .gst-top-left {
    position: absolute;
    right: 15px;
    top: 5px;
    font-size: 8px;
  }

  .row-bordered-sc-group {
    border-top: solid rgb(61, 36, 168) 4px;
    border-bottom: solid rgb(61, 36, 168) 4px;
  }

  .row-no-border-sc-group {
    border-style: none;
  }

  .r-m-10-sc-group {
    margin-right: 10px;
  }

  .floating-spinner {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    text-align: center;
  }

  .spinner-holder {
    position: relative;
    display: inline-block;
  }

  .footer-sc-group {
    height: 40px;
    margin: auto;
    padding-top: 10px;
    display: flex;
    justify-content:space-between;
  }

  .footer-left{
    display: flex;
  }

  .footer-right{
    display: flex;
  }