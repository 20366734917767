
.attachment-table {
    width: 60%;
    float: left;
    background-color: #f4f5fc;
    height: calc(100vh - 145px);
  }

  .attachment-table-no-bottom {
    width: 60%;
    float: left;
    background-color: #f4f5fc;
    height: calc(100vh - 120px);
  }

  #attachment-grand-parent {
    width: 60%;
  }

  .attachment-image-group {
    border-left: solid rgb(238, 229, 229) 1px;
    height: calc(100vh - 120px);
    overflow-y:scroll; 
    white-space: nowrap;
    width: 100%;
  }

  #attachment-iframe {
    width: 100%;
    height: calc(100vh - 120px);
  }

  #attachment-iframe-with-bottom {
    width: 100%;
    height: calc(100vh - 140px);
  }

  .button-container {
    display: flex;
    /*align-items: center;
    justify-content: center;*/
    flex-direction: row-reverse;
    padding: 10px;
  }



