.blog-container {
    
}

.blog-text, .editor-content p {
    line-height: 32px;
    letter-spacing: -0.003em;
    margin-top: 2em;
    font-size: 21px;
    margin-bottom: -0.46em;
    font-family: charter, Georgia, Cambria, "Times New Roman", Times, serif;
    color: rgba(41, 41, 41, 1);
    font-style: normal;
    word-break: break-word;
    font-weight: 400;
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

.blog-h2 {
    font-weight: 500;
    line-height: 28px;
    margin-top: 1.72em;
    letter-spacing: 0;
    margin-bottom: -0.31em;
    font-size: 22px;
    color: rgba(41, 41, 41, 1);
    font-family: sohne, "Helvetica Neue", Helvetica, Arial, sans-serif;
    word-wrap: break-word;
    font-style: normal;
}

.blog-h1 {
    line-height: 36px;
    margin-top: 1.95em;
    font-size: 30px;
    letter-spacing: 0;
    margin-bottom: -0.28em;
    font-weight: 500;
    font-style: normal;
    color: rgba(41, 41, 41, 1);
    font-family: sohne, "Helvetica Neue", Helvetica, Arial, sans-serif;
    box-sizing: inherit;
    word-wrap: break-word;
    display: block;
}

.center-cropped {
    width: 100%;
    height: 200px;
    background-position: center center;
    background-repeat: no-repeat;
  }

#blog-header {
    position: relative;
    margin-bottom: 65px;
}

#blog-header-emoji {
    position: absolute;
    font-size: 110px;
    bottom: -85px;
    left: 45px;
}

figure {
    display: inline-block;
}
figure img {
    vertical-align: top;
}
figure figcaption {
    text-align: center;
}

.ul-li, .editor-content li {
    margin-top: 1.05em;
    letter-spacing: -0.003em;
    line-height: 32px;
    font-size: 21px;
    padding-left: 0px;
    margin-left: 30px;
    margin-bottom: -0.46em;
    font-family: charter, Georgia, Cambria, "Times New Roman", Times, serif;
    font-style: normal;
    color: rgba(41, 41, 41, 1);
    font-weight: 400;
    box-sizing: inherit;
    display: list-item;
    text-align: -webkit-match-parent;
    overflow-wrap: break-word;
    word-break: break-word;
}

.side-by-side {
    display: flex;
    margin-top:110px; 
}

.shift-top {
    margin-top:110px;
} 

#edit-board {
    position: relative;
    width: calc(100% - 700px);
    margin-right: 20px;
    border-left: 1px solid black;
    background-color: rgb(250, 252, 252);
}

#component-group {
    position: -webkit-sticky; 
    position: sticky; 
    margin: 20px;
    right: 0; 
    top: 110px;
    width: 10%;
    height: 80vh;
}

.widget-button {
    padding-top: 100%; /* 1:1 Aspect Ratio */
    position: relative;
    margin-bottom: 20px;
    border: 1px solid rgb(155, 176, 202);
}

.widget-button div {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  #editable-components {
    min-height: 80vh;
    width: calc(90% - 40px);
    margin-left: 20px;
    position: absolute;
    top: 0px;
    right: 0px;
  }

  #editable-components > * {
      margin-top: 10px;
  }

.edit-wrapper {
    border: 1px solid rgb(184, 182, 182);
}
  .demo-editor {
      padding: 10px;
  }

.just-flex {
    display: flex;
}

.just-flex strong {
    margin-right: 10px;
}

.editor-content blockquote {
    letter-spacing: -0.003em;
    line-height: 32px;
    font-size: 21px;
    font-style: italic;
    font-family: charter, Georgia, Cambria, "Times New Roman", Times, serif;
    word-break: break-word;
    color: rgba(41, 41, 41, 1);
    font-weight: 400;
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 40px;
    margin-inline-end: 40px;
    box-shadow: rgb(41 41 41) 3px 0px 0px 0px inset;
    padding-left: 23px;
    margin-left: -20px;
}

.editor-content  a {
    color: inherit;
}

.editor-content  h1, .editor-content  h1 span {
    line-height: 36px;
    margin-top: 1.95em;
    font-size: 30px;
    letter-spacing: 0px;
    margin-bottom: -0.28em;
    font-weight: 500;
    font-family: sohne, "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.editor-content ol {
    display: block;
    list-style-type: decimal;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
    padding-left: 0px;
    margin-bottom: 40px;
}

.editor-content ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
    padding-left: 0px;
    margin-bottom: 40px;
}

.newsletter {
    padding: 20px;
    padding-bottom: 40px;
    border: 1px solid beige;
}

.video-container {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
}
.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}