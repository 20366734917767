
@media screen and (max-width: 960px) {

    #feature-holder_a, #feature-holder_b, #feature-holder_c, #feature-holder_d {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-areas: 
                    "a"
                    "b";
        margin-top: 40px !important;
    }

    .feature_image_holder {
        grid-area: a;
    }

    .feature_data_holder {
        grid-area: b;
    }

    .feature_image_holder > div {
        margin-bottom: 20px;
    }

    #feature_data_b, #feature_data_d, #feature_image_c, #feature_image_a {
        margin-top: 0px !important;
    }

    .feature_title_holder {
        display: flex;
        align-items: center;
        margin-bottom: 1em;
    }

    .feature_title_holder > .avatar-md {
        margin-bottom: 0px  !important;
        margin-right: 10px;
    }

}

