#new_schdule_row {
    display: flex;
    padding: 10px;
    border: solid rgb(238, 229, 229) 1px;
    background-color: white;
  }

#client_field_pickup {
    margin-left: 2%;
    margin-right: 4%;
    width: 26%;
}

.phone_field {
    width: 30%;
}

#pick_up_date {
    margin-left: 4%;
}

#schedule_button {
    margin-left: 4%;
    width: 10%;
}

#old_schdules {
    margin-top: 20px;
    height: 75vh;
}