.bd-container {
    width: 100%;
    margin: auto;
    padding-top: 80px;
    display: flex;
}

#business-detail-left {
    padding-top: 5px;
    margin-left: 20px;
    width: 380px;
    border: 1px solid black;
    height: calc(100vh - 100px);;
    padding-left: 10px;
}

#business-detail-right {
    width: calc(100% - 450px);
    background-color: #f8fafe;
    height: calc( 100vh - 100px);
    margin-right: 10px;
    padding-left: 10px;
    margin-left: 10px;
    position: relative;
}

#client-name{
    padding-left: 35px;
}

.horizontal{
    display: flex;
    align-items:top;
}

.horizontal-center{
    display: flex;
    align-items:center;
}

.vertival {
    display: flex;
    flex-direction: column;
}

.horizontal p {
    margin-right: 5px;
}

#scan-business-detail{
    align-items: center;
    display: flex;
    flex-direction: column;
}

#scan-business-detail img{
    margin-right: 10px;
}

.anti-boorstrap-p {
    margin: 0;
    padding: 0;
}

#recycle_bin_container{
    position: absolute;
    bottom: 10px;
    right: 10px;
}