#register-container{
    padding-top: 80px;
}

#register-stepper {
    width: 600px;
}

.cb-container {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
}

.register-button {
   width: 100px;
}

.register-paper {
    width: 600px;
    height: 400px;
}

.register-vertical {
    display: flex;
    flex-direction: column;
}

.paper-center {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
}

.small-font {
    font-size: 10px;
}

.loaderHolder {
    position: relative;
    display: inline-block;
}

.floating-spinner {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    text-align: center;
}