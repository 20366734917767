.two-sc-group {
    border-left: solid black 1px;
    height: calc(100vh - 130px);
    overflow-y:scroll; 
    white-space: nowrap;
    width: calc(35% - 2px);
  }

  #image-holder{
      width: 100%;
      position: relative;
      height: auto;
  }
  .img-abs {
    position: absolute;
    z-index: 998;
    opacity: 0.3;
  }

  .rect-abs {
    position: absolute;
    z-index: 998;
    background-color: black;
    color: aliceblue;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 12px;
    border-radius: 5px;
    /*animation: float 3s ease-in-out infinite;*/
    box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
  }

  .div-horizontal {
    position: absolute;
    top: 10px;
    left: 10px;
    color: #007bff;
  }

  @keyframes float {
	0% {
		box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
		transform: translatey(0px);
	}
	50% {
		box-shadow: 0 25px 15px 0px rgba(0,0,0,0.2);
		transform: translatey(-5px);
	}
	100% {
		box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
		transform: translatey(0px);
	}
}